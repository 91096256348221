/* src/pages/ItemDetailPage.css */

.item-detail-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr; /* 3 columns: left (1fr), center (2fr), right (1fr) */
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.item-detail-title {
    font-size: 2em;
    margin-bottom: 20px;
}

.item-detail-left {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item-detail-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
}

.item-sku {
    color: #555;
    font-size: 0.9em;
    margin-top: -10px; /* Margin from top */
    margin-bottom: 20px; /* Space between SKU and the next content */
}

.item-detail-placeholder {
    width: 100%;
    height: 300px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555;
    font-size: 1.2em;
    margin-bottom: 20px;
}

.item-detail-center {
    display: flex;
    flex-direction: column;
}

.item-detail-info {
    margin-top: 20px;
}

.item-detail-info p {
    margin: 10px 0;
    font-size: 1.1em;
}

.pricing-tiers {
    margin-top: 20px;
}

.pricing-tiers h2 {
    margin-bottom: 10px;
    color: #555;
}

.pricing-table {
    width: 100%;
    border-collapse: collapse;
}

.pricing-table th,
.pricing-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

.pricing-table th {
    background-color: #f2f2f2;
    color: #333;
}

.pricing-table td {
    color: #555;
}

.item-detail-right {
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Step instructions */
.item-detail-right p {
    font-weight: bold;
    font-size: 1.1em;
    color: #444;
    margin-bottom: 10px;
}

/* Price / Add to Cart container */
.rental-price {
    font-size: 1em;
    color: #444;
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ffd699;
    border-radius: 6px;
    width: 90%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Base Add to Cart button (no return date selected) */
.rental-price button {
    width: auto;
    padding: 8px 14px;
    background: #ffd699;
    color: white;
    font-size: 0.95em;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Hover effect for the base button */
.rental-price button:hover {
    background: #ffb347;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

/* The 'active' class (blue highlight + subtle animation) */
.rental-price button.button-active {
    background-color: #2B6CB0; /* a deeper blue */
    color: #fff;
    /* Optional small pop animation */
    animation: buttonPop 0.3s forwards;
}

/* Hover effect for the active button */
.rental-price button.button-active:hover {
    background-color: #2a65a3; /* slightly darker blue */
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* go-to-cart-btn */
/* Hover effect for the base button */
.go-to-cart-btn {
    background: #2B6CB0;
    color: #fff;
    transform: translateY(-2px);
    width: auto;
    padding: 8px 14px;
    font-size: 0.95em;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: 7px;
}

/* Hover effect for the active button */
.go-to-cart-btn:hover {
    background-color: #2a65a3; /* slightly darker blue */
    color: #fff;
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Keyframes for the pop effect once the class is first applied */
@keyframes buttonPop {
    0% {
        transform: scale(0.95);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* This selector targets the 'today' cell in react-datepicker to override and remove the highlight*/
/* Optionally, if you want to style it differently, e.g. underline: */
.react-datepicker__day--today:not(.react-datepicker__day--selected) {
    background-color: transparent !important;
    color: #d98d35 !important;
    text-decoration: underline !important;
}

.react-datepicker__day--selected {
    background-color: #ffd699 !important; /* Soft pale orange */
    color: #333 !important;
    border-radius: 5px;
}

/* Hover effect for selected days */
.react-datepicker__day--selected:hover {
    background-color: #ffb347 !important; /* Slightly deeper orange */
}

/* Improve calendar appearance */
.item-detail-right .react-datepicker {
    width: 100%;  /* Ensure it stretches across */
    max-width: 445px; /* Set a max width so it doesn’t get too wide */
    font-size: 1.1em; /* Increase font size for better readability */
    border: 2px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    background: #fff;
}

.react-datepicker__day {
    width: 45px; /* Increase width of each day cell */
    height: 45px; /* Increase height for better spacing */
    line-height: 45px; /* Center the text */
    font-size: 1em; /* Slightly larger text */
    border-radius: 6px;
}

/* Make selected date range stand out */
.react-datepicker__day--in-range {
    background: linear-gradient(to right, #ffd699, #feb47b) !important;
    color: white !important;
    border-radius: 5px;
}

/* Style the hover effect for available dates */
.react-datepicker__day:not(.react-datepicker__day--disabled):hover {
    background-color: #ffd699;
    color: #333;
    border-radius: 5px;
}

/* Animated price change */
@keyframes priceUpdate {
    0% { opacity: 0.5; transform: scale(0.95); }
    100% { opacity: 1; transform: scale(1); }
}

.rental-price p {
    animation: priceUpdate 0.3s ease-in-out;
}

/* Subtle pulse effect on the button */
@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

.rental-price button:hover {
    animation: pulse 0.3s ease-in-out;
}
