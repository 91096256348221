/* src/pages/AdminRentalPolicyPage.css */

/* Container Styles */
.admin-rental-policy-container {
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #f9f9f9;
}

.admin-rental-policy-container h1 {
    margin-bottom: 1rem;
    text-align: center;
}

/* Each policy row */
.policy-row {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
}

.policy-row label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
}

.policy-row span {
    margin-left: 0.5em;
}

/* Label and input styling */
.policy-label {
    margin-right: 0.5em;
    width: 60%;
    font-weight: bold;
}

.policy-input {
    width: 100px;
    padding: 0.3rem;
    margin-left: auto;
}

/* The Save button */
.policy-save-button {
    display: inline-block;
    margin-top: 1em;
    padding: 0.6rem 1.2rem;
    font-size: 1rem;
    color: #fff;
    background-color: #367fa9;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.policy-save-button:hover {
    background-color: #286090;
}