.video-production-container {
    margin: 20px;
}

.video-production-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
}

.video-production-search-input {
    max-width: 200px;
    padding: 6px 8px;
    font-size: 1rem;
}

.items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    align-items: start;
    padding: 20px;
}