/* src/pages/ProjectorPage.css */
.projector-page-container {
    margin: 20px;
}

/* Put title and search on same line */
.projector-header {
    display: flex;
    align-items: center;
    gap: 16px;    /* space between title and search bar */
    margin-bottom: 16px;
}

/* Basic styling for the search box */
.projector-search-input {
    max-width: 200px;
    padding: 6px 8px;
    font-size: 1rem;
}
