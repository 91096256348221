/* src/pages/AllItemsPage.css */
.all-items-container {
    margin: 20px auto;
}

.all-items-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.all-items-search-container {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
}

.all-items-search-container input {
    max-width: 200px;
    padding: 6px 8px;
    font-size: 1rem;
}

