.checkout-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.checkout-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
}

/* For the payment summary / totals area */
.checkout-totals {
    margin-bottom: 20px;
    background: #fafafa;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 5px;
}

.checkout-totals p {
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
}

/* Payment method radio buttons section */
.payment-method-section {
    margin-top: 20px;
    padding: 15px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.payment-method-section label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

/* For partial deposit sub-options (nonrefundable fee) */
.payment-method-suboption {
    margin-left: 2em;
    margin-top: 0.5em;
    color: #555;
}

/* Basic read-only fields for name/email */
.checkout-userinfo {
    margin-top: 20px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
}

.checkout-userinfo label {
    font-weight: bold;
}

/* Payment Error Box for specific messages */
.payment-error-box {
    background-color: #ffe6e6; /* light red/pink */
    border: 1px solid #ffb3b3; /* a bit darker border */
    padding: 15px;
    border-radius: 5px;
    color: #cc0000; /* red text */
    margin-bottom: 20px;
    font-weight: bold;
}

/* The In-Store button */
.instore-button {
    background: #d98d35;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.instore-button:hover {
    background: #b3742b;
}

/* Back to Cart button */
.go-back-to-cart-btn {
    background: #d98d35;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    margin-left: auto;
    text-align: right;
}

.go-back-to-cart-btn:hover {
    background: #b3742b;
}

/* The PayPal button container if you want to style it, for a margin top */
.paypal-button-container {
    margin-top: 20px;
}

.button-container {
    display: flex;
    justify-content: space-between; /* Ensure buttons are spaced correctly */
    width: 100%; /* Ensure the container takes up full width */
}