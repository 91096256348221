/* src/pages/CameraPage.css */
.camera-page-container {
    margin: 20px;
}

.camera-page-container h1 {
    margin-bottom: 20px;
    text-align: center;
}

.camera-header {
    /* Ensures the <h1> and input are on the same line */
    display: flex;
    align-items: center;
    gap: 16px; /* Space between the title and search bar */
    margin-bottom: 16px;
}

/* Example styling for the search input */
.camera-search-input {
    max-width: 200px;
    padding: 5px 8px;
}

.items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    align-items: start;
    padding: 20px;
}