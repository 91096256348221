.lighting-page-container {
    margin: 20px;
}

.lighting-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
}

.lighting-search-input {
    max-width: 200px;
    padding: 5px 8px;
}
